<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="650px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Player Availability</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="
              togglePlayerAvailabilityModal({ show: false });
              closeCallBackModal();
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <!--   <v-col cols="12" class="px-2">
                <v-text-field
                  label="Task Title"
                  outlined
                  dense
                  class="formFields"
                  color="#7253CF"
                >
                </v-text-field>
              </v-col>-->
          <v-row>
            <v-col cols="4" class="pl-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="formatedStartDate"
                    prepend-inner-icon="mdi-calendar-range"
                    label="Date"
                    v-bind="attrs"
                    readonly
                    color="#7253CF"
                    class="formFields"
                    v-on="on"
                    @change="this.getEventSlotList()"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  no-title
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!--    <v-col cols="4"   sm="6"  class="pl-2">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="formatedEndDate"
                    prepend-inner-icon="mdi-calendar-range"
                    label="End Date"
                    v-bind="attrs"
                    readonly
                    color="#7253CF"
                    class="formFields"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>-->
            <v-col cols="4" class="pl-2">
              <v-btn
                color="#38227A"
                dark
                class="text-capitalize px-7 mr-5"
                @click="getPlayerAvailabilty()"
                height="45px"
                style="border-radius: 10px; height: 40px"
                >Search</v-btn
              >
            </v-col>
          </v-row>
          <div class="table-div">
            <h4 v-if="nameFlag">Player Name</h4>
            <v-data-table
              hide-default-footer
              fixed-header
              :items="campaignDataTable.items"
              :items-per-page="campaignDataTable.page_info.detail_count"
              :server-items-length="campaignDataTable.page_info.detail_count"
              :options.sync="dataOptions"
            >
              <template v-slot:item="row">
                <tr class="text-flex" style="font-size: 14px">
                  <td>
                    <v-checkbox
                      v-model="row.item.selected"
                      color="#7253CF"
                      class="formFields"
                      :label="row.item.name"
                      @change="checkbox(row.item.id, row.item.selected)"
                    ></v-checkbox>
                  </td>
                </tr>
              </template>
              <template
                v-slot:footer
                v-if="campaignDataTable.items[0] && $vuetify.breakpoint.smAndUp"
              >
                <div>
                  <v-col cols="4" class="pl-2">
                    <v-btn
                      color="#38227A"
                      dark
                      class="text-capitalize px-7 mr-5"
                      @click="submitEventSlot()"
                      height="45px"
                      style="border-radius: 10px; height: 40px"
                      v-if="playerStatus == 'new'"
                      >Submit</v-btn
                    >
                    <v-btn
                      color="#38227A"
                      dark
                      class="text-capitalize px-7 mr-5"
                      @click="editEventSlot()"
                      height="45px"
                      style="border-radius: 10px; height: 40px"
                      v-if="playerStatus == 'existing'"
                      >Submit</v-btn
                    >
                  </v-col>
                  <v-divider></v-divider>
                  <!--<v-row no-gutters class="pt-6 px-2">
                    <v-col
              class="mb-4"
              align-self="center"
              cols="12"
              xs="12"
              sm="6"
              md="4"
            >
              <div
                class="justify-center justify-md-start d-flex px-5 tableHeader-text"
              >
                <div class="alignSelf-center mr-3">Show</div>
                <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                  <v-select
                    class=""
                    :items="itemsPerPageList"
                    hide-details
                    v-model="campaignDataTable.page_info.detail_count"
                    dense
                  ></v-select>
                </div>

                <div class="alignSelf-center">
                  {{ campaignDataTable.page_info.page_number }}
                  of
                  {{ campaignDataTable.page_info.total_page }}
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>

                    <v-col cols="12" xs="12" sm="4" md="4">
                      <div>
                        <v-pagination
                          v-model="
                            campaignDataTable[$API_KEYS.PAGE_INFO][
                              $API_KEYS.PAGE_NUMBER
                            ]
                          "
                          :length="
                            campaignDataTable[$API_KEYS.PAGE_INFO][
                              $API_KEYS.TOTAL_PAGE
                            ]
                          "
                          :total-visible="5"
                          color="#D30024"
                          class="pagination"
                        >
                        </v-pagination>
                      </div>
                    </v-col>
                  </v-row>-->
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_PLAYER_AVAILABILITY,
  API_PLAYER_AVAILBLE_EVENT_SLOT_POST,
  API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import moment from "moment";
import {
  DATA,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
} from "@/constants/APIKeys";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import _ from "lodash";
export default {
  name: "ListCalendarManagement",
  components: {},
  data() {
    return {
      loading: false,
      formLoading: false,
      nameFlag: false,
      campaignData: {},
      menu: false,
      menu1: false,
      start_date: "",
      end_date: "",
      players_user_id_lists: [],
      playerStatus: "",
      eventSlotId: "",
      campaignDataTable: {
        headers: [
          {
            width: "10%",
            sortable: false,
            text: "Player Name",
            align: "center",
            style: "font-size: 16px;",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 150,
        },
        itemsPerPageList: [10, 15, 20, 30, 40, 50, 100],
      },
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getPlayerAvailabilty",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.togglePlayerAvailabilityModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.playerAvailability.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
    formatedStartDate: {
      get() {
        if (this.start_date) {
          return moment(this.start_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEndDate: {
      get() {
        if (this.end_date) {
          return moment(this.end_date).format("YYYY-MM-DD");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },
  created() {
    this.debounceGetPlayerAvailability = _.debounce(
      this.getPlayerAvailabilty,
      500
    );
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
    //Watcher for detecting change in PAGE_NUMBER
    "campaignDataTable.page_info.page_number": function () {
      this.getPlayerAvailabilty();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "campaignDataTable.page_info.detail_count": function () {
      this.getPlayerAvailabilty();
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      togglePlayerAvailabilityModal:
        "calendarManagement/togglePlayerAvailability",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getEventSlotList();
    },
    closeCallBackModal() {
      (this.campaignDataTable.items = []),
        (this.start_date = ""),
        (this.end_date = ""),
        (this.nameFlag = false);
    },

    getPlayerAvailabilty() {
      this.players_user_id_lists = [];
      const self = this;
      this.campaignDataTable.loading = true;
      const successHandler = (res) => {
        this.nameFlag = true;
        self.campaignDataTable.items = res.data.player_list;
        // alert(res.data.player_status)
        // alert(res.data.event_slot_id)
        self.playerStatus = res.data.player_status;
        for (let i = 0; i < res.data.player_list.length; i++) {
          const player = res.data.player_list[i];
          if (player.selected) {
            this.players_user_id_lists.push(player.id);
          }
        }
        self.eventSlotId = res.data.event_slot_id;
        self.campaignDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.campaignDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.campaignDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];

        self.campaignDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.campaignDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[ITEMS_PER_PAGE] =
        this.campaignDataTable.page_info.detail_count;
      dataTableParams[PAGE_NUMBER] =
        this.campaignDataTable[PAGE_INFO][PAGE_NUMBER];
      dataTableParams.start_date = this.start_date;
      //   dataTableParams.end_date = this.end_date;
      Axios.request_GET(
        API_PLAYER_AVAILABILITY,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    reloadFunction() {
      location.reload();
    },
    checkbox1(id) {
      this.players_user_id_lists.push(id);
    },
    checkbox(id, selected) {
      if (selected) {
        this.players_user_id_lists.push(id);
      } else {
        const index = this.players_user_id_lists.indexOf(id);
        if (index !== -1) {
          this.players_user_id_lists.splice(index, 1);
        }
      }
    },
    editEventSlot() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        location.reload();
        this.showToast({
          message: "Updated successfully",
          color: "s",
        });
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };
      let formData = {};
      formData["id"] = this.eventSlotId;
      formData["start_date"] = this.start_date;
      formData["note"] = this.note;
      formData["players"] = this.players_user_id_lists;
      Axios.request_PATCH(
        API_PLAYER_AVAILBLE_EVENT_SLOT_PATCH,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitEventSlot() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        location.reload();
        this.showToast({
          message: "Updated successfully",
          color: "s",
        });
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.showToast({
          message: res,
          color: "e",
        });
        this.formLoading = false;
      };
      let formData = {};
      formData["start_date"] = this.start_date;
      formData["note"] = this.note;
      formData["players"] = this.players_user_id_lists;
      Axios.request_POST(
        API_PLAYER_AVAILBLE_EVENT_SLOT_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
